import {Col, Row} from "react-bootstrap";
import {IntroHomepage} from "../component/IntroHomepage";
import {useEffect, useState} from "react";
import {HighlightText} from "../component/HighlightText";
import {MinecraftServerStatusCheckerPage} from "./MinecraftServerStatusCheckerPage";
import {PasswordGenPage} from "./PasswordGenPage";
import {ProjectsPage} from "./ProjectsPage";

export function HomePage() {
    const website_naming_list = ["website", "site", "page", "portal", "webpage", "testing-site", "portfolio",
        "abandoned-project", "CV", "internet-presence", "kickstarter", "wiki", "documentation", "contact-site",
        "blog", "forum", "social-media", "online-store",]
    const [websiteNaming, setWebsiteNaming] = useState("website")

    useEffect(() => {
        const interval = setInterval(() => {
            setWebsiteNaming(website_naming_list[Math.floor(Math.random() * website_naming_list.length)])
        }, 1000 * 5)

        return () => clearInterval(interval);
    }, []);

    return <>
        <Row>
            <Col>
                <h1 className={"text-center display-1 text-light"} style={{marginTop: "5rem"}}>Chenko</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <h1 className={"text-center display-2 text-light my-5"}>Welcome to my <HighlightText
                    highlight={"danger"}>{websiteNaming}</HighlightText>!</h1>
            </Col>
        </Row>
        <Row>
            <IntroHomepage/>
        </Row>

        <Row>
            <Col>
                <h1 className={"text-center display-2 text-light mt-5"}>Projects</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <ProjectsPage/>
            </Col>
        </Row>

        <Row className={"mt-4"}>
            <Col>
                <h1 className={"text-center display-2 text-light my-5"}>Featured Tools</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <PasswordGenPage/>
            </Col>
        </Row>
        <Row className={"mb-5"}>
            <Col>
                <MinecraftServerStatusCheckerPage/>
            </Col>
        </Row>
    </>
}
