import {useLocation, useParams} from "react-router-dom";
import {firestoreDB} from "../services/firebaseUtil";
import {doc, getDoc} from 'firebase/firestore';
import {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";

export function DetailedProjectPage() {
    const {project} = useParams()
    const [content, setContent] = useState(null)
    //TODO add a firebase database and fetch the data from there
    //Get single project from firebase by id


    useEffect(() => {
        console.log("content: ", content)
        if (content === null) {

            getDoc(doc(firestoreDB, 'projects', project)).then((doc) => {
                if (doc.exists()) {
                    console.log("Document data:", doc.data());
                    setContent(doc.data())
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch((error) => {

            })
        }

    }, [content, project])
    return (
        <>
            {content &&
                <>
                    <Row>
                        <Col>
                            <h1 className={"my-5 text-light "}>Detailed Project Page - <span
                                className={"text-capitalize"}>{content.name}</span></h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <Card className={"bg-dark text-light"}>
                                <Card.Body>
                                    <Card.Title>{content.name}</Card.Title>
                                    <Card.Text>
                                        {content.description}
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <a className="text-muted" target={"_blank"} href={content.link} rel="noreferrer">Show me</a>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </>


            }

        </>
    )
}