import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {NavLink, useNavigate} from "react-router-dom";
import {NavDropdown} from "react-bootstrap";

export function NavBar() {
    const navigate = useNavigate()
    return (<Container>
        <Navbar  variant={"dark"} expand="lg" className={"navbar-inverse rounded-bottom nav-bg-dark"}>
            <Container >
                <NavLink className={"navbar-brand"}
                         to={"/"}>Chenko</NavLink>

                <Navbar.Toggle aria-controls="navbarScroll"/>

                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className=" my-2 my-lg-0"
                        navbarScroll
                    >
                        <Nav.Link  href={"/projects"} onClick={(e) => {
                            e.preventDefault()
                            navigate("/projects")}
                        }>
                            Projects
                        </Nav.Link>
                        <NavDropdown title="Tools" id="navbarScrollingDropdown">
                            <NavDropdown.Item href="/tools/discord-text-converter" onClick={(e) => {
                                e.preventDefault()
                                navigate("/tools/discord-text-converter")
                            }} >
                                Discord Text Converter
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/tools/discord-text-converter" onClick={(e) => {
                                e.preventDefault()
                                navigate("/tools/mc-status")
                            }} >
                                MC Server Status
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/tools/discord-text-converter" onClick={(e) => {
                                e.preventDefault()
                                navigate("/tools/password-gen")
                            }} >
                                Password Generator
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/tools/qrcode-gen" onClick={(e) => {
                                e.preventDefault()
                                navigate("/tools/qrcode-gen")
                            }} >
                                QR Code Generator
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#WIP" disabled>
                                See All
                            </NavDropdown.Item>
                        </NavDropdown>


                        <Nav.Link  href={"/who-am-i"} onClick={(e) => {
                            e.preventDefault()
                            navigate("/who-am-i")}
                        }>
                            About me
                        </Nav.Link>
                        <Nav.Link href="#WIP" disabled>
                            More coming soon!
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar> </Container>)
}