import {Card, Col, Form, Row} from "react-bootstrap";
import {Button, Checkbox, FormControlLabel, FormGroup, Slider} from "@mui/material";
import {useState} from "react";
import {passwordGen} from "../util/passwordGen";
import {copyUtil} from "../util/copyUtil";


export function PasswordGenPage() {

    const [length, setLength] = useState([12, 18]);
    const [inclNumbers, setInclNumbers] = useState(true);
    const [inclSymbols, setInclSymbols] = useState(true);
    const [inclUpper, setInclUpper] = useState(true);
    const [inclLower, setInclLower] = useState(true);
    const [password, setPassword] = useState("");
    const handleChange = (event, newValue) => {
        setLength(newValue);
    };
    return (
        <>
            <Row>
                <Col>
                    <h1 className={"my-5 text-light koshgarian"}>Password Generator</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={3}>
                    <Card className={"roboto"}>
                        <Card.Header as="h5">Settings</Card.Header>
                        <Card.Body>
                            <FormGroup>
                                <FormControlLabel label={"Include Numbers"}
                                                  control={<Checkbox defaultChecked onChange={(e) => {
                                                      setInclNumbers(e.target.checked)
                                                  }}/>}/>
                                <FormControlLabel label={"Include Symbols"}
                                                  control={<Checkbox defaultChecked onChange={(e) => {
                                                      setInclSymbols(e.target.checked)
                                                  }}/>}/>
                                <FormControlLabel label={"Include Uppercase"}
                                                  control={<Checkbox defaultChecked onChange={(e) => {
                                                      setInclUpper(e.target.checked)
                                                  }}/>}/>
                                <FormControlLabel label={"Include Lowercase"}
                                                  control={<Checkbox defaultChecked onChange={(e) => {
                                                      setInclLower(e.target.checked)
                                                  }}/>}/>
                            </FormGroup>
                            <Slider
                                value={length}
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                                min={1}
                                max={64}
                            />
                        </Card.Body>
                    </Card>
                </Col>


                <Col xs={12} md={9}>
                    <Card className={"roboto"}>
                        <Card.Header as="h5">Generated Password</Card.Header>
                        <Card.Body>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                const chosenLength = Math.abs(Math.floor(Math.random() * (length[1] - length[0] + 1)) + length[0]);
                                setPassword(passwordGen(chosenLength, inclUpper, inclLower, inclNumbers, inclSymbols))

                            }}>
                                <Row>
                                    <FormGroup>
                                        <Form.Control type={"input"} spellCheck={false} placeholder={"Secret Password"}
                                                      value={password} disabled/>
                                    </FormGroup>
                                </Row>
                                <Row className={"justify-content-center"}>
                                    <Button type={"submit"} className={" my-2 me-2  w-25 "} variant="contained">Generate
                                        new password!</Button>
                                    <Button type={"button"} className={" my-2 ms-2  w-25 "} variant="contained"
                                            color={"success"} onClick={(e) => {
                                        e.preventDefault()
                                        copyUtil(password)

                                    }}>Copy!</Button>
                                </Row>

                            </Form>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>

        </>
    )
}