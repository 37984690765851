import {Card, Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {HighlightText} from "../component/HighlightText";
import {MyButton} from "../component/MyButton";

export function AboutMePage() {
    const style = {
        backgroundColor: "#4158D0",
        backgroundImage: "linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)",
        border: "none",
    }
    const navigate = useNavigate();
    return (
        <>
            <Row>
                <Col>
                    <h1 className={"my-4 text-light font-monospace"}>About Me</h1>
                </Col>
            </Row>
            <Row>
                <Col md={9}>
                    <Card className={"bg-dark text-light font-monospace"}>
                        <Card.Header>
                            Updated: 7/06/2023
                        </Card.Header>
                        <Card.Body>
                            <Card.Title className={"font-monospace"}>Hi there!</Card.Title>
                            <br/>
                            <Card.Text>
                                My name is <HighlightText>Chenko Mortier</HighlightText>, a 22-year-old software
                                developer from <HighlightText>Belgium</HighlightText>. <br/>I just graduated from <HighlightText> Thomas More
                                University College</HighlightText> in
                                Sint-Katelijne-Waver. As a self-taught developer, I am always on the lookout for new challenges and
                                opportunities to learn and grow. I am highly passionate about programming, and I love to
                                explore new technologies and methods to improve my skills.

                                <br/>
                                <br/>
                                On my personal website, you'll find an 'array'
                                of <HighlightText>tools</HighlightText> and <HighlightText>projects</HighlightText> that
                                I've built using
                                my skills in programming. I have a passion for creating solutions that make life easier,
                                and I enjoy experimenting with new ideas.
                                <br/>
                                <br/>
                                In addition to my educational background and experience, I am also
                                a <HighlightText>gamer</HighlightText>, and my
                                website reflects that passion as well. You'll find that I have created a few
                                tools <HighlightText>related to</HighlightText> gaming, and I am always on the lookout
                                for ways to improve the gaming
                                experience.
                                <br/>
                                <br/>
                                With regards to my skills, I have a strong background in <HighlightText>web
                                development</HighlightText> and <HighlightText>Java</HighlightText>.
                                Additionally, I recently had my second <HighlightText>internship</HighlightText> at <HighlightText>Formica
                                digital</HighlightText>, where I gained
                                hands-on experience in the field. I will be returning to <HighlightText>Formica
                                Digital</HighlightText> for my <HighlightText>actual
                                job</HighlightText> in a few months, further solidifying my skills and knowledge
                                in the
                                industry. I will be honing my skills and expanding my knowledge in the
                                field here!
                                <br/>
                                <br/>
                                If you have any questions or would like to get in touch with me, you can use
                                the <HighlightText>contact
                                form</HighlightText> provided. I'd love to hear from you!
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col lg={{span: 6, offset: 3}}>
                                    <MyButton variant={"warning"} className={"w-100 inter"} gradient={"orange"}
                                              onClick={() => {
                                                  navigate("/contact")
                                              }}>Contact Me</MyButton>
                                </Col>
                            </Row>

                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
