export function passwordGen(length,
                            uppercase,
                            lowercase,
                            numbers,
                            symbols) {
    let password = "";
    const upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lower = "abcdefghijklmnopqrstuvwxyz";
    const num = "0123456789";
    const sym = "!@#$%^&*_-+=";
    let charSet = "";
    if (uppercase) {
        charSet += upper;
    }
    if (lowercase) {
        charSet += lower;
    }
    if (numbers) {
        charSet += num;
    }
    if (symbols) {
        charSet += sym;
    }
    for (let i = 0; i < length; i++) {
        password += charSet.charAt(Math.floor(Math.random() * charSet.length));
    }
    return password;

}