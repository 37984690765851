import axios from "axios";
import {toast} from "react-toastify";

export default function sendMcMessageWithRest(serverIP, serverPort, userName, message){
    fetch(`${serverIP}:${serverPort}`, {
        method: "POST",
        body: JSON.stringify({
            username: userName,
            content: message
        }),
        headers: {
            "Content-Type": "application/json"
        }

    }).then((res) => {
        console.log(res)
        toast.success("Message sent!")
    }).catch((err) => {
        console.log(err)
        toast.error("Error sending message to server")
    })
    /*
    axios.post(`${serverIP}:${serverPort}`, {
        username: userName,
        content: message
    }, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })*/
}