import {getAuth} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {collection, doc, setDoc} from "firebase/firestore";
import {firestoreDB, simpleConverter} from "../../services/firebaseUtil";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {toast} from "react-toastify";
import {MyButton} from "../../component/MyButton";


const handleResolved = async (id, boolSwitch) => {

    await setDoc(doc(firestoreDB, "contact", id), {
        resolved: boolSwitch
    }, { merge: true });
}

export function AdminDashboard() {
    const auth = getAuth();
    const navigate = useNavigate();
    // show resolved or not resolved
    const [resolved, setResolved] = useState(false);



    const [user, setUser] = useState(null);
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (!user) navigate("/login")
            else setUser(user);
        });
    }, [auth]);

    const query = collection(firestoreDB, 'contact').withConverter(simpleConverter);
    const [values, loading, error] = useCollectionData(query);
    const [objects, setObjects] = useState([])

    useEffect(() => {
        console.log(values, loading, error)
        if (values) {
            setObjects(values)
        }
    }, [objects, values,error])


    return (
        <>
            <Row className={"mt-4"}>
                <Col md={3}>
                    <h1 className={"display-5 text-light koshgarian"}>Dashboard</h1>
                </Col>
                {auth.currentUser && <>

                    <Col md={{span: 6}}>
                        <h1 className={"display-5 text-light koshgarian"}>-
                            Welcome, {auth.currentUser.displayName.split(" ")[0]}! -</h1>
                    </Col>
                    <Col md={3}> <MyButton gradient={"danger"} className={"w-100"} variant={"danger"} onClick={() => {
                        auth.signOut().then(() => {
                            navigate("/login")
                        }).catch((error) => {
                            console.log(error)
                        })
                    }}>Sign Out</MyButton> </Col>
                </>
                }
            </Row>
            {/*Toolbar*/}
            {/*TODO put this in its own component*/}
            <Row className={"mt-4"}>
                <Col md={3}>
                    <MyButton className={"w-100"} variant={resolved ? "success" : "warning"}
                              gradient={resolved ? "green" : "orange"} onClick={() => {
                        setResolved(!resolved)
                    }}>{resolved ? "Unresolved" : "Resolved"}</MyButton>
                </Col>
                {
                    !resolved &&
                    <Col md={3}>
                        <MyButton className={"w-100"} variant={"danger"} gradient={"dark-red"} onClick={() => {
                            if (objects.filter(obj => {
                                return obj.resolved === false
                            }).length === 0) {
                                toast.warn("No messages to mark as resolved.", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                return;
                            }

                            objects.forEach((obj) => {
                                handleResolved(obj.id, true)
                            })
                            toast.error("All messages marked as resolved.", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });

                        }}>Mark all as: RESOLVED</MyButton>
                    </Col>
                }

            </Row>



            {!auth.currentUser && <>
                <p className={"text-light"}>You are not logged in. Please login to access the dashboard.</p>
            </>}

            {auth.currentUser && objects && <>
                <Row className={"mt-4"}>

                        {objects.filter(obj => {
                            return obj.resolved === resolved
                        }).sort((obj, x) => {
                            // sort by date
                            return new Date(x.date) - new Date(obj.date)
                        }).map((obj) => (
                            <Col key={obj.id} lg={6} className={"mb-5"}>
                                <Card className={"my-3 w-100 h-100"}>
                                    <Card.Header>
                                        <Row>
                                            <Col>
                                                From: {obj.name}
                                            </Col>
                                            <Col>Email: {obj.email}</Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                Date: {new Date(obj.date).toLocaleString('en-GB', { timeZone: 'CET' })}
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title>{obj.subject}</Card.Title>
                                        <Card.Text>{obj.message}</Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button variant={"danger"} onClick={() => handleResolved(obj.id, !resolved)}>Mark {resolved ? "Not Resolved":"Resolved"}</Button>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                </Row>
            </>}
            {!resolved && auth.currentUser && objects.filter(obj => {

                return obj.resolved === false
            }).length === 0 && <Row>
                <Col>
                    <Card className={"bg-dark text-light my-3 w-100"}>
                        <Card.Header>Resolved Messages</Card.Header>
                        <Card.Body>
                            <Card.Title>All messages have been resolved.</Card.Title>
                            <Card.Text>There are no messages to show.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>}


        </>)
}