import PropTypes from "prop-types";
import {Card, Col, Row} from "react-bootstrap";
import Avatar from "boring-avatars";
import {useNavigate} from "react-router-dom";

export function ShowCase(props) {
    const {objects} = props
    const navigate = useNavigate()
    return (
        <>
            <Row>

                    {objects.map((obj, index) => (
                        <Col key={obj.id}>
                        <Card className={"my-3 w-100"}  onClick={() => {
                            navigate("/project/" + obj.id)
                        }}>
                            <Card.Body>
                                <Card.Title>{obj.name}</Card.Title>
                                <Avatar size={64} name={obj.name} variant={"marble"}/>
                            </Card.Body>
                        </Card></Col>
                    ))}
            </Row>

        </>
    )
}

//showcase props types and default props
ShowCase.propTypes = {
    objects: PropTypes.arrayOf(PropTypes.object).isRequired
}
