import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import provider from "../services/auth_google_provider_create";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {MyButton} from "./MyButton";

export function SignInWithGoogleButton(props) {
    const {auth} = props
    const navigate = useNavigate();

    return <>
        <MyButton onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            signInWithPopup(auth, provider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    //const credential = GoogleAuthProvider.credentialFromResult(result);
                    //const token = credential.accessToken;
                    // The signed-in user info.
                    //const user = result.user;
                    toast.success("Login Success")
                    navigate("/dashboard")
                }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                console.log("ErrorMessage: ", errorMessage)
                console.log("Other error: ", errorCode, email, credential)
                toast.error("There was a problem signing you in. Please try again.")
            });
        }
        }>
            Sign in With Google
        </MyButton>
    </>
}

SignInWithGoogleButton.propTypes = {
    auth: PropTypes.any.isRequired
}