import {Col, Row} from "react-bootstrap";
import {ShowCase} from "../component/ShowCase";
import {collection} from "firebase/firestore";
import {firestoreDB} from "../services/firebaseUtil";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";


const projectConverter = {
    toFirestore: undefined,
    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        return {...data, id: snapshot.id}
    }
}

export function ProjectsPage() {

    const query = collection(firestoreDB, 'projects').withConverter(projectConverter);
    const [values, loading, error] = useCollectionData(query);
    const featuredProjects = [{name: "HY", id: "SkSNas86LhFF4WdAw3Tt"}]
    const [allProjects, setAllProjects] = useState([])
    useEffect(() => {
        if (values && allProjects.length === 0) {
            setAllProjects(values)
        }
    }, [allProjects, values])
    return (
        <>
            <Row>
                <Col>
                    <h1 className={"my-5 text-light"}>Featured Projects</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ShowCase objects={featuredProjects}/>
                </Col>
            </Row>


            <Row>
                <Col>
                    <h1 className={"my-5 text-light"}>All Projects</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ShowCase objects={allProjects}/>
                </Col>
            </Row>


        </>
    )
}