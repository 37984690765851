import {Card, Col, Form, Row} from "react-bootstrap";
import {useState} from "react";
import {addDoc, collection} from "firebase/firestore";
import {firestoreDB} from "../services/firebaseUtil";
import {toast} from "react-toastify";
import {MyButton} from "../component/MyButton";

export function ContactPage() {
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");


    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            setValidated(true);
            return true;
        }
        console.log("Sending message...");
        //Submit to firestore
        //things to submit: name, email, subject, message, current date/time, resolved: false(default)
        await addDoc(collection(firestoreDB, "contact"), {
            name: name,
            email: email,
            subject: subject,
            message: message,
            date: Date.now(),
            resolved: false
        }).then(() => {
            toast.success("Message sent!");
            fetch("https://discord-webhook-noti.terroflys.workers.dev/");
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
            setValidated(false);
        }).catch((error) => {
            toast.error("Error sending message");
            console.error("Error sending contact message: ", error);
        });


    };
    return (<>
        <Row>
            <Col>
                <h1 className={"my-5 text-light koshgarian"}>Contact Form</h1>
            </Col>
        </Row>
        <Row>


            <Col className={"mb-3"}>
                <Card>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Card.Header>
                            <strong>Contact</strong>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control required type="text" placeholder="Enter your name"
                                                      onChange={(e) => {
                                                          setName(e.target.value)
                                                      }} value={name}/>
                                        <Form.Control.Feedback type="invalid">Please enter your
                                            name.</Form.Control.Feedback>
                                        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Subject</Form.Label>
                                        <Form.Control required type="text" placeholder="Subject" onChange={(e) => {
                                            setSubject(e.target.value)
                                        }} value={subject}/>
                                        <Form.Control.Feedback type="invalid">Please enter a
                                            subject.</Form.Control.Feedback>
                                        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={"my-3"}>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control required type="email" placeholder="Enter your email"
                                                      onChange={(e) => {
                                                          setEmail(e.target.value)
                                                      }} value={email}/>
                                        <Form.Control.Feedback type="invalid">Please enter your
                                            email.</Form.Control.Feedback>
                                        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control required as="textarea" rows={5} placeholder="Enter your message"
                                                      onChange={(e) => {
                                                          setMessage(e.target.value)
                                                      }} value={message}/>
                                        <Form.Control.Feedback type="invalid">Please enter your
                                            message.</Form.Control.Feedback>
                                        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>


                        </Card.Body>

                        <Card.Footer>
                            <Row>
                                <Col lg={5}>
                                    <small className={"text-muted"}>Made by Chenko - All fields are required</small>
                                </Col>
                                <Col>
                                    <MyButton type={"submit"} className={"w-100"} variant={"primary"}
                                              gradient={"blue"}>Send</MyButton>
                                </Col>
                            </Row>

                        </Card.Footer>
                    </Form>
                </Card>
            </Col>
            <Col xs={12} md={4} className={"mb-3"}>
                <Card className={"bg-dark text-light"}>
                    <Card.Body>
                        <Card.Title>Contact Form</Card.Title>
                        <Card.Text>
                            Enter the form to contact me!
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>


    </>)
}

