import {Card, Col, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";

export function InputOutputTextArea(props) {
    const {
        titleOne,
        titleTwo,
        placeholderOne,
        placeholderTwo,
        showButton,
        buttonText,
        buttonOnClick,
        buttonVariant,
        buttonDisabled,
        extraFooter,
        textAreaOneValue,
        textAreaTwoValue,
        textAreaOneChange,
        textAreaTwoChange,
        header,
        textAreaOneDisabled,
        textAreaTwoDisabled,

    } = props;
    return <Row>
        <Col>
            <Card className={"roboto"}>
                <Card.Header as="h5">{header}</Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                {titleOne}
                            </Form.Label>
                            <Form.Control spellCheck={false}
                                          as={"textarea"}
                                          rows={3} className={"mb-3"}
                                          placeholder={placeholderOne}
                                          onChange={textAreaOneChange} value={textAreaOneValue}/>
                        </Form.Group>
                    </Form>
                    <Form.Group>
                        <Form.Label>
                            {titleTwo}
                        </Form.Label>
                        <Form.Control placeholder={placeholderTwo}
                                      as={"textarea"} rows={6}
                                      className={"mb-3 p-3 w-100"} value={textAreaTwoValue} disabled
                                      onChange={textAreaTwoChange}/>
                    </Form.Group>
                    {showButton && <Button variant={buttonVariant} onClick={buttonOnClick}>{buttonText}</Button>}
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">Made by Chenko {extraFooter}</small>
                </Card.Footer>
            </Card>
        </Col>
    </Row>
}

//Prop Types
InputOutputTextArea.propTypes = {
    titleOne: PropTypes.string,
    titleTwo: PropTypes.string,
    placeholderOne: PropTypes.string,
    placeholderTwo: PropTypes.string,
    showButton: PropTypes.bool,
    buttonText: PropTypes.string,
    buttonOnClick: PropTypes.func,
    buttonVariant: PropTypes.string,
    buttonDisabled: PropTypes.bool,
    extraFooter: PropTypes.string,
    textAreaOneValue: PropTypes.string,
    textAreaTwoValue: PropTypes.string,
    textAreaOneChange: PropTypes.func,
    textAreaTwoChange: PropTypes.func,
    header: PropTypes.string,
    textAreaOneDisabled: PropTypes.bool,
    textAreaTwoDisabled: PropTypes.bool,
}

//Default Props
InputOutputTextArea.defaultProps = {
    titleOne: "Input",
    titleTwo: "Output",
    placeholderOne: "Input",
    placeholderTwo: "Output",
    showButton: true,
    buttonText: "Click Me!",
    buttonOnClick: () => {
        console.log("Button Clicked!")
    },
    buttonVariant: "primary",
    buttonDisabled: false,
    extraFooter: "",
    textAreaOneValue: "",
    textAreaTwoValue: "",
    textAreaOneChange: () => {
    },
    textAreaTwoChange: () => {
    },
    header: "Title Here",
    textAreaOneDisabled: false,
    textAreaTwoDisabled: true,

}