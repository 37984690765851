import {Button} from "react-bootstrap";
import PropTypes from "prop-types";
import "../css/customButtonStyle.css";

export function MyButton(props) {
    const {children, variant, type, className, gradient, onClick} = props;

    return <Button className={"btn-grad-" + gradient + " " + className} variant={variant} type={type}
                   onClick={onClick}>{children}</Button>
}

MyButton.defaultProps = {
    variant: "primary",
    type: "button",
    className: "",
    gradient: "blue",
    onClick: () => {
    },

}
MyButton.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    gradient: PropTypes.string,
    onClick: PropTypes.func,
}