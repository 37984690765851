import {Card, Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {useState} from "react";
import {toast} from "react-toastify";
import {checkServer, ipChecker} from "../util/mcServerCheck";

export function MinecraftServerStatusCheckerPage() {
    const [serverIP, setServerIP] = useState("");
    const [showResult, setShowResult] = useState(false)
    const [result, setResult] = useState("")


    return (<><Row className={"mt-5"}>
        <Col>
            <h1 className={"display-5 text-light koshgarian"}>Minecraft Server Checker</h1>
        </Col>
    </Row>
        <Row>
            <Col>
                <Card className={"roboto"}>
                    <Card.Header as="h5">Check IP</Card.Header>
                    <Card.Body>
                        <Form onSubmit={e => e.preventDefault()}>
                            <Form.Group>
                                <Form.Label>
                                    Server IP to check
                                </Form.Label>
                                <Form.Control spellCheck={false}
                                              type={"text"}
                                              className={"mb-3"}
                                              onChange={(e) => { setServerIP(e.target.value) }}
                                              placeholder={"192.168.0.2:25565"}/>
                            </Form.Group>
                        </Form>
                        {showResult &&
                            <Form.Group>
                                <Form.Label>
                                    Result!
                                </Form.Label>
                                <Form.Control spellCheck={false}
                                              as={"textarea"}
                                              rows={7} className={"mb-3"}
                                              value={result} disabled/>
                            </Form.Group>
                        }

                        <Button variant="success" onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (ipChecker(serverIP)) {
                                checkServer(serverIP).then((result) => {
                                    setResult(result)
                                    setShowResult(true)
                                })
                            } else {
                                toast.error("Invalid IP!")
                            }

                        }}>Check Server</Button>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Made by Chenko | Using: <a href={"https://mcstatus.io/"} target={"_blank"} rel="noreferrer">mcstatus.io</a> </small>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    </>)
}