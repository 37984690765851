import {Alert, Card, Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {useCallback, useState} from "react";
import useWebSocket from "react-use-websocket";

export function McSocketChat() {
    const [userName, setUserName] = useState("Anonymous")
    const [message, setMessage] = useState("")

    const [localSocketUrl, setLocalSocketUrl] = useState('ws://localhost:25566/chat');
    const [socketUrl, setSocketUrl] = useState('ws://mc.terroflys.com:25566/chat');

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);



    return (<>
        <Alert variant={"warning"} style={{fontSize: "1.75rem"}}
               className={"text-center text-black roboto"}>This was made when my minecraft server (for
            friends) was online. <br></br>By the time you are using this it is possible this will no longer
            work.</Alert>

        <Row className={"mt-5"}>
            <Col>
                <h1 className={"display-5 text-light koshgarian"}>Send a message to my minecraft server</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <Card className={"roboto"}>
                    <Card.Header as="h5">Send Message</Card.Header>
                    <Card.Body>
                        <Form onSubmit={e => e.preventDefault()}>
                            <Form.Group>
                                <Form.Label>
                                    Username
                                </Form.Label>
                                <Form.Control spellCheck={false}
                                              type={"text"}
                                              className={"mb-3"}
                                              value={userName}
                                              onChange={(e) => {
                                                  setUserName(e.target.value)
                                              }}
                                              placeholder={"Chenko"}/>
                            </Form.Group>
                            <Form.Group>

                                <Form.Label>
                                    Message
                                </Form.Label>
                                <Form.Control spellCheck={true}
                                              type={"text"}
                                              className={"mb-3"}
                                              as={"textarea"}
                                              value={message}
                                              onChange={(e) => {
                                                  setMessage(e.target.value)
                                              }}
                                              placeholder={"Hello There!"}/>
                            </Form.Group>
                        </Form>


                        <Button variant="success" onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            //socket thing here
                            //sendMcMessageWithSocketIo(userName, message)
                            sendMessage(`{"username": "${userName}", "content": "${message}"}`)


                        }}>Send Message</Button>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Made by Chenko | made for my Custom made PaperMC plugin
                            that uses Javelin.io for websockets
                        </small>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    </>)
}