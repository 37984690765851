import {toast} from "react-toastify";

export function copyUtil(text) {
    navigator.clipboard.writeText(text).then(() => {
        //Toast to show the user that it has successfully copied
        toast.success("Text Copied!")
    }).catch((e) => {
        //error message
        console.log("Error message:", e)
        toast.error("Could not copy text.")
    })
}